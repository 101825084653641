<template>
	<div class="text-center">
		<v-overlay :value="active" color="sidebarBackground" opacity="1">
			<v-progress-circular :indeterminate="active" color="primary" />
		</v-overlay>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'FeedbackSplash',
	computed: {
		...mapGetters({
			active: 'splash/active'
		})
	}
}
</script>
